export const logo = [
  '1680 652',
  `
  <title>genotek logo</title>
  <g transform="translate(0.000000,652.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M1348 5762 c-155 -162 -184 -311 -97 -492 29 -60 115 -149 1039
-1075 642 -645 1020 -1031 1044 -1067 20 -32 51 -88 68 -125 27 -57 32 -79 32
-148 1 -44 -3 -93 -7 -110 l-9 -30 31 29 c46 44 128 166 162 241 42 93 59 175
59 288 0 165 -44 305 -141 448 -27 40 -234 254 -549 567 -278 277 -742 740
-1031 1028 l-525 525 -76 -79z"/>
<path d="M2972 5367 l-472 -472 195 -195 c107 -107 196 -192 199 -190 199 190
664 665 690 704 128 193 109 374 -57 553 -38 40 -72 73 -76 73 -3 0 -219 -213
-479 -473z"/>
<path d="M5415 4925 c-239 -44 -413 -174 -523 -390 -31 -61 -87 -229 -110
-330 -52 -226 -77 -604 -62 -955 18 -395 71 -647 183 -865 62 -120 193 -250
307 -304 174 -83 375 -112 642 -93 173 13 226 20 376 53 l92 21 0 749 0 749
-185 0 -185 0 -2 -652 -3 -653 -150 2 c-237 2 -362 45 -463 160 -165 185 -228
436 -239 943 -18 827 129 1227 475 1299 32 7 175 11 362 11 l310 0 0 94 0 94
-32 11 c-183 59 -612 90 -793 56z"/>
<path d="M14152 3463 l3 -1438 135 -3 c74 -2 159 0 187 3 l53 6 2 522 3 522
194 -305 c106 -168 257 -405 335 -527 l141 -223 204 0 c166 0 202 2 198 13 -6
15 -129 203 -481 735 l-227 343 66 102 c36 56 178 272 315 480 138 208 250
380 250 383 0 2 -92 4 -205 4 l-205 0 -26 -42 c-145 -232 -531 -840 -545 -859
-18 -23 -19 -3 -19 849 l0 872 -190 0 -190 0 2 -1437z"/>
<path d="M11638 4298 l-3 -233 -132 -3 -133 -3 0 -124 0 -125 120 0 120 0 0
-567 c0 -729 10 -848 85 -1006 115 -243 428 -319 803 -196 l62 21 0 83 0 83
-177 4 c-160 3 -182 5 -219 25 -83 44 -140 137 -165 267 -5 27 -9 326 -9 665
l0 617 138 12 c135 12 237 29 340 58 l52 15 0 95 0 94 -265 0 -265 0 0 225 0
225 -175 0 -175 0 -2 -232z"/>
<path d="M1794 4188 c-149 -151 -196 -205 -217 -250 -37 -77 -50 -146 -46
-237 3 -63 10 -89 37 -142 31 -61 144 -209 160 -209 4 0 151 144 327 320 l320
320 -195 195 -196 196 -190 -193z"/>
<path d="M7080 4104 c-195 -42 -306 -127 -390 -299 -99 -201 -151 -557 -136
-920 16 -366 81 -574 225 -715 193 -190 552 -239 984 -134 l107 26 0 83 0 83
-277 5 c-299 5 -337 11 -433 63 -67 37 -114 90 -156 178 -47 98 -64 186 -71
354 l-6 142 513 0 513 0 -6 173 c-24 606 -174 888 -510 958 -109 22 -263 24
-357 3z m267 -233 c88 -41 154 -139 192 -287 22 -85 41 -228 41 -311 l0 -53
-326 0 -327 0 6 133 c9 199 58 361 138 452 69 78 187 106 276 66z"/>
<path d="M8885 4109 c-86 -13 -141 -33 -240 -90 -49 -28 -94 -53 -101 -56 -7
-2 -16 18 -23 56 l-12 61 -165 0 -164 0 0 -1030 0 -1030 185 0 185 0 0 886 0
885 53 24 c128 59 295 71 411 29 68 -24 137 -94 167 -169 l24 -60 3 -797 3
-798 185 0 185 0 -3 828 c-3 813 -4 828 -25 904 -59 207 -165 312 -360 353
-74 16 -214 18 -308 4z"/>
<path d="M10435 4113 c-290 -36 -459 -173 -544 -438 -98 -306 -105 -861 -16
-1195 54 -200 159 -347 298 -415 127 -62 208 -78 402 -78 143 0 183 3 250 22
131 35 200 73 276 151 117 119 182 281 213 535 33 270 28 564 -14 809 -35 205
-94 331 -209 447 -66 66 -86 80 -158 107 -45 18 -121 38 -168 47 -79 14 -252
18 -330 8z m261 -244 c193 -73 274 -352 261 -899 -10 -373 -52 -549 -158 -665
-58 -62 -125 -89 -224 -89 -201 0 -304 121 -361 424 -22 116 -31 548 -14 707
20 196 67 347 136 440 70 93 231 130 360 82z"/>
<path d="M13045 4104 c-300 -66 -454 -305 -504 -784 -13 -124 -13 -499 -1
-615 26 -236 101 -427 211 -535 184 -181 539 -235 949 -143 63 14 123 29 133
34 14 8 17 24 17 88 l0 79 -282 4 c-254 5 -290 7 -350 27 -132 43 -212 125
-262 270 -33 100 -45 170 -53 319 l-6 122 517 0 518 0 -6 128 c-4 70 -11 177
-17 237 -42 443 -191 687 -464 760 -88 24 -311 29 -400 9z m257 -226 c144 -43
247 -283 248 -575 l0 -83 -325 0 -325 0 0 58 c0 89 18 230 40 310 64 233 197
340 362 290z"/>
<path d="M1395 3778 c-75 -83 -158 -250 -181 -368 -33 -168 6 -363 107 -538
25 -43 297 -321 1082 -1107 l1048 -1050 75 78 c102 106 138 180 142 297 4 96
-14 161 -70 245 -17 26 -476 491 -1019 1034 -1038 1038 -1054 1055 -1110 1198
-26 66 -36 182 -21 236 6 20 9 37 7 37 -2 0 -29 -28 -60 -62z"/>
<path d="M2817 2892 l-317 -317 194 -194 194 -194 74 67 c127 118 308 316 333
367 33 64 55 151 55 215 0 59 -30 152 -67 209 -42 65 -126 165 -137 165 -6 0
-154 -143 -329 -318z"/>
<path d="M1644 1718 c-362 -365 -381 -389 -419 -522 -40 -136 5 -276 129 -411
l65 -70 478 477 478 478 -195 195 -195 195 -341 -342z"/>
</g>
`,
]

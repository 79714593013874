export default [
  {
    component: 'CNavTitle',
    name: 'Витрины заказов',
  },
  {
    component: 'CNavItem',
    name: 'Онлайн-поиск',
    to: '/browse/showcase/',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Просмотр документов (онлайн)',
    to: '/browse/new_showcase/',
    icon: 'cil-screen-desktop',
  },
  {
    component: 'CNavItem',
    name: 'Просмотр документов (архив)',
    to: '/browse/new_column/',
    icon: 'cil-paperclip',
  },
  {
    component: 'CNavItem',
    name: 'Оформление отчета',
    to: '/browse/report/',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Интервью',
    to: '/browse/interview_showcase/',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: '______________________________________________________________',
    class: 'my-class',
  },
  {
    component: 'CNavItem',
    name: 'Мои задачи',
    to: '/browse/my_tasks/',
    icon: 'cil-task',
  },
  {
    component: 'CNavItem',
    name: 'Human Bank',
    to: '/pages/humanbank/',
    icon: 'cil-search',
  },
  {
    component: 'CNavItem',
    name: 'Инструкции',
    to: '/browse/instruction/',
    icon: 'cil-description',
  },
  {
    component: 'CNavItem',
    name: 'Инструменты',
    to: '/browse/tools/',
    icon: 'cil-pen-nib',
  },
  {
    component: 'CNavItem',
    name: 'Примеры',
    to: '/browse/example/',
    icon: 'cil-pencil',
  },
]

<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <!--
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem class="px-3">
          <CNavLink href="#">Все результаты</CNavLink>
        </CNavItem>
        <CNavItem class="px-3">
          <CNavLink href="#">Поиск</CNavLink>
        </CNavItem>
      </CHeaderNav>
      -->
      <CHeaderNav>
        <CNavItem class="px-3">
          <a
            :href="
              $router.resolve({
                name: 'Profile',
              }).href
            "
            style="
              position: absolute;
              right: 120px;
              top: 20px;
              font-weight: bold;
              color: rgb(42, 134, 3);
            "
          >
            Мой уровень: {{ this.user_level }}
          </a>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
  <CenterSpinner v-if="loading"></CenterSpinner>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import CenterSpinner from '@/components/CenterSpinner.vue'
import { logo } from '@/assets/brand/logo'
import { GET_MY_PROFILE_API_HOOK, fetch_api_json } from '@/common/api_endpoints'

export default {
  name: 'AppHeader',
  mixins: [CenterSpinner],
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      user_level: null,
    }
  },
  async mounted() {
    await this.getUserData()
  },
  methods: {
    async getUserData() {
      let url
      url = GET_MY_PROFILE_API_HOOK
      const res = await fetch_api_json(url, 'POST', {})
      let data = await res.json()
      this.user_level = data.max_level
    },
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
<style scoped></style>
